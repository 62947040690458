import { createSlice } from "@reduxjs/toolkit";

const layoutSlice = createSlice({
  name: "layoutSlice",
  initialState: {
    headerHeightRedux: 0,
  },
  reducers: {
    setHeaderHeightRedux: (state, action) => {
      state.headerHeightRedux = action.payload;
    },
  },
});

export const { setHeaderHeightRedux } = layoutSlice.actions;

export default layoutSlice.reducer;
