import React from 'react'
// components
import About from "../components/about/About";

function AboutPage() {
  return (
    <About/>
  )
}

export default AboutPage;