import React, { useState, useEffect, useRef } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import { getPoemsByTag, getPoemsByYear } from "../../redux/slices/poemSlice";
import { resetCurrentWord } from "../../redux/slices/wordSlice";
// react-swiper
import { Swiper, SwiperSlide } from "swiper/react";
// swiper-styles
import "swiper/css";
import "swiper/css/effect-creative";
// swiper-modules
import { EffectCreative } from "swiper";
// css
import "./book.css";
import "./bookSlider.css";

const Page = React.forwardRef((props, ref) => {
  return (
    <div
      className={props.class === "left" ? "page left" : "page right"}
      ref={ref}
    >
      {/* <p className="pageTitle">{props.children.title}</p> */}
      <p className={props.class === "left" ? "leftTitle" : "rightTitle"}>
        {props.currentTag === "" ? props.currentYear : props.currentTag}
      </p>
      <p className="pageTitle">{props.children.title}</p>
      <p className="pageBody">{props.children.body}</p>
      <div className="page-footer">{props.number}</div>
    </div>
  );
});

export default function Book() {
  const dispatch = useDispatch(null);

  const currentTag = useSelector((state) => state.words.current);
  const currentYear = useSelector((state) => state.words.year);
  const poems = useSelector((state) => state.poems.poems);

  const swiperRef = useRef(null);

  useEffect(() => {
    // reset swiper
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [currentTag, currentYear]);

  return (
    <div className="flip-book">
      {(currentTag !== "" || currentYear !== "") && poems.length > 0 && (
        <Swiper
          ref={swiperRef}
          grabCursor={true}
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[EffectCreative]}
        >
          {poems.map((poem, index) => {
            if (index % 2 === 0) {
              return (
                // <div className="poem-container" >
                <SwiperSlide key={index}>
                  <Page
                    currentTag={currentTag}
                    currentYear={currentYear}
                    number={index + 1}
                    class={"left"}
                  >
                    {poem}
                  </Page>
                  {poems[index + 1] !== undefined ? (
                    <Page
                      currentTag={currentTag}
                      currentYear={currentYear}
                      number={index + 2}
                      class={"right"}
                    >
                      {poems[index + 1]}
                    </Page>
                  ) : null}

                  {poems.length === index + 1 ? (
                    <Page
                      currentTag={currentTag}
                      currentYear={currentYear}
                      number={index + 2}
                      class={"right"}
                    >
                      ''
                    </Page>
                  ) : null}
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
      )}
    </div>
  );
}
