import React, { useState } from "react";
import "./admin.css";
import { useDispatch, useSelector } from "react-redux";
import { tabs } from "./tabs";
import { setCurrentTab } from "../../redux/slices/tabsSlice";

const Admin = () => {
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.tabs.currentTab);

  const handleTabClick = (e) => {
    dispatch(setCurrentTab(e.target.id));
  };

  return (
    <div className="container">
      <div className="tabs">
        {tabs.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
            className={currentTab === `${tab.id}` ? "tab active-tab" : "tab"}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="content admin">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div>
                <p className="title">{tab.title}</p>
                {tab.content}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Admin;
