// css
import "./cartoon.css";

export default function Cartoon() {
  return (
    <div className="cartoons">
      <div className="cartoon">

        <video
          autobuffer="autobuffer"
          autoplay="autoplay"
          loop="loop"
          controls="controls"
        >
          <source
            src="/assets/mult.mp4"
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
        </video>
      </div>
    </div>
  );
}
