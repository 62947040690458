import { useState, useEffect } from "react";
// css
import "./press.css";

export default function Press() {
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState("");

  function handleClick(e) {
    // get src of image
    let src = e.target.src;
    setSrc(src);
    setOpen(true);
  }

  return (
    <div className="press">
      <div className="container">
        <div className="press-images">
          <div className="group group-1">
            <img
              src="/assets/book-sale/prec/3.png"
              className="image-6"
              onClick={(e) => handleClick(e)}
              alt="press"
            />
            <div className="vertical">
              <div className="horizontal">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/book-sale/prec/2.png`}
                  className="image-1"
                  onClick={(e) => handleClick(e)}
                  alt="press"
                />
              </div>

              <img
                src="/assets/book-sale/prec/mozayka.png"
                className="image-2"
                onClick={(e) => handleClick(e)}
                alt="press"
              />
            </div>
            <div className="horizontal">
              <img
                src="/assets/book-sale/prec/reet1.png"
                className="image-3"
                onClick={(e) => handleClick(e)}
                alt="press"
              />
              <img
                src="/assets/book-sale/prec/reet2.png"
                className="image-4"
                onClick={(e) => handleClick(e)}
                alt="press"
              />
            </div>

            <img
              src="/assets/book-sale/prec/faynberg.png"
              className="image-5"
              onClick={(e) => handleClick(e)}
              alt="press"
            />
            <img
              src="/assets/book-sale/prec/4.png"
              className="image-7"
              onClick={(e) => handleClick(e)}
              alt="press"
            />
          </div>

          <div className="group group-2">
              <img
                src="/assets/book-sale/prec/gorlova.png"
                className="image-8"
                onClick={(e) => handleClick(e)}
                alt="press"
              />
            <div className="horizontal">
              <img
                src="/assets/book-sale/prec/5.png"
                className="image-9"
                onClick={(e) => handleClick(e)}
                alt="press"
              />
              <img
                src="/assets/book-sale/prec/6.png"
                className="image-10"
                onClick={(e) => handleClick(e)}
                alt="press"
              />
            </div>

            <img
              src="/assets/book-sale/prec/9.png"
              className="image-11"
              onClick={(e) => handleClick(e)}
              alt="press"
            />
          </div>
        </div>
        <div className={`modal ${open ? "active" : ""}`}>
          <div className="modal-content">
            <img src={src} alt="press" />
          </div>
          <div>
            <button onClick={() => setOpen(false)}>
              <img src="/assets/close.png" alt="Close modal" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
