import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const token = localStorage.getItem("token") || "";
const BASE_URL = "https://node.inesse.uz";
// const BASE_URL = "http://localhost:4000"; 


export const getTags = createAsyncThunk("words/getTags", async () => {
  const response = await axios.get(`${BASE_URL}/api/poem/random`);

  const randomTags = response.data.map((tagArray) => {
    let tags = tagArray;
    if (typeof tags === 'string') {
      try {
        tags = JSON.parse(tags);
      } catch (error) {
        // Пропускаем итерацию, если не удается распарсить теги
        return null;
      }
    }
    const randomIndex = Math.floor(Math.random() * tags.length);
    return tags[randomIndex];
  }).filter(tag => tag !== null);
  return randomTags;
});

export const getPoemsByTag = createAsyncThunk("words/getPoems", async (tag) => {
  const response = await axios.post(`${BASE_URL}/api/client/login`, tag);
  return response.data;
});

export const getBlockedWords = createAsyncThunk(
  "words/blockedWords",
  async () => {
    const response = await axios.get(`${BASE_URL}/api/blockedwords`);
    return response.data;
  }
);

export const addBlockedWord = createAsyncThunk(
  "api/blockedwords",
  async (data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${BASE_URL}/api/blockedwords`,
      data,
      config
    );

    return response.data;
  }
);
// export async function sendHistory(data) {
//   const response = await axios.post(`${BASE_URL}/api/history`, data);
//   return response;
// }

// export const getHistory = createAsyncThunk("history/getHistory", async () => {
//   const response = await axios.get(`${BASE_URL}/api/history`);
//   return response.data;
// });

export const deleteBlockedWords = createAsyncThunk(
  "words/deleteBlockedWords",
  async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.delete(
      `${BASE_URL}/api/blockedwords/${id}`,
      config
    );
    return response.data;
  }
);

const wordSlice = createSlice({
  name: "wordsSlice",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    all: [],
    current: "",
    year: "",
    blockedWords: [],
    history: "",
  },
  reducers: {
    setCurrentWord: (state, action) => {
      state.current = action.payload;
    },
    setCurrentYear: (state, action) => {
      state.year = action.payload;
    },
    resetCurrentWord: (state) => {
      state.current = "";
    },
    resetCurrentYear: (state) => {
      state.year = "";
    },
    deleteBlockWord(state, action) {
      state.blockedWords = state.blockedWords.filter(
        (element) => element._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getTags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.all = action.payload;
      })
      .addCase(getTags.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log(action.error);
      })
      .addCase(getBlockedWords.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlockedWords.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blockedWords = action.payload;
      })
      .addCase(getBlockedWords.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log(action.error);
      })
      .addCase(addBlockedWord.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBlockedWord.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blockedWords.push(action.payload);
      })
      .addCase(addBlockedWord.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.log(action.error);
      });
  },
});

export const {
  setCurrentWord,
  resetCurrentWord,
  resetCurrentYear,
  setCurrentYear,
  deleteBlockWord,
} = wordSlice.actions;

export default wordSlice.reducer;
