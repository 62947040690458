import { useState, useEffect, useRef } from "react";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentYear,
  resetCurrentWord,
  // sendHistory,
} from "../../redux/slices/wordSlice";
import {
  getPoemsByYear,
  handleSendHistory,
} from "../../redux/slices/poemSlice";
// hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";
// react-router-dom
import { useNavigate } from "react-router-dom";

export default function Years({ headerHeight, setYearsWidth }) {
  const dispatch = useDispatch(null);
  const { isLoading } = useSelector((state) => state.poems);
  const { height, width } = useWindowDimensions();

  const navigate = useNavigate();

  const [yearsHeight, setYearsHeight] = useState("0");
  const yearsRef = useRef();

  function handleCLick(i) {
    dispatch(setCurrentYear(i));
    dispatch(resetCurrentWord());
    dispatch(getPoemsByYear(i)).then((res) => {
      dispatch(
        handleSendHistory({
          tag: null,
          year: i,
        })
      );
    });

    navigate("/");
  }

  // useEffect(() => {
  //   setYearsHeight(height - headerHeight);
  // }, [headerHeight, height]);

  // useEffect(() => {
  //   setYearsWidth(yearsRef.current.offsetWidth);
  // }, [yearsRef?.current?.offsetWidth, width, setYearsWidth]);

  return (
    <>
      <div className="years yearsLeft" ref={yearsRef}>
        {Array.from({ length: 15 }, (_, i) => (
          <a key={i} onClick={() => handleCLick(i + 1989)}>
            {i + 1989}
          </a>
        ))}
      </div>
      <div className="years yearsRight">
        {Array.from({ length: 16 }, (_, i) => (
          <a key={i} onClick={() => handleCLick(i + 2004)}>
            {i + 2004}
          </a>
        ))}
      </div>
    </>
  );
}
