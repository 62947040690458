import { configureStore } from "@reduxjs/toolkit";
import wordSliceReducer from "./slices/wordSlice";
import poemsSliceReducer from "./slices/poemSlice";
import userSliceReducer from "./slices/userSlice";
import tagSliceReducer from "./slices/tagsSlice";
import searchSliceReducer from "./slices/searchSlice";
import tabsSliceReducer from "./slices/tabsSlice";
import layoutSliceReducer from "./slices/layoutSlice";

import { apiSlice } from "./slices/apiSlice";
const store = configureStore({
  reducer: {
    words: wordSliceReducer,
    poems: poemsSliceReducer,
    user: userSliceReducer,
    tags: tagSliceReducer,
    search: searchSliceReducer,
    tabs: tabsSliceReducer,
    layout: layoutSliceReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
