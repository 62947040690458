import { useState, useEffect, useRef } from "react";
// react-router-dom
import { Routes, Route, useLocation } from "react-router-dom";
// redux
import { Provider } from "react-redux";
import store from "./redux/store";
// pages
import Home from "./pages/HomePage";
import About from "./pages/AboutPage";
import Book from "./pages/BookPage";
import Voice from "./pages/VoicePage";
import Poem from "./pages/PoemPage";
import Author from "./pages/AuthorPage";
import AdminPage from "./pages/AdminPage";
import MetaDataPage from "./pages/MetaDataPage";
import CartoonPage from "./pages/CartoonPage";
import PressPage from "./pages/PressPage";
// fonts
import "./fonts/TrixieCyr-Cameo.otf";

// components
import Header from "./components/header/Header";
import Years from "./components/years/Years";
import BottomBorder from "./components/years/BottomBorder";
import MetaInspector from "./components/metaInspector/MetaInspector";

// css
import "./App.css";

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [yearsWidth, setYearsWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const location = useLocation();

  // resize with scale
  const verySpecificDesignRef = useRef(null);
  const [style, setStyle] = useState({
    transform: "scale(1)",
    transformOrigin: "top left",
  });

  useEffect(() => {
    const verySpecificDesign = verySpecificDesignRef.current;
    const scale = Math.min(window.innerWidth / 1910, window.innerHeight / 1015);
    verySpecificDesign.style.transform = `scale(${scale}) translateZ(0)`;
    // get width
    let height = verySpecificDesign.getBoundingClientRect().height;
    let width = verySpecificDesign.getBoundingClientRect().width;

    let windowHeight = window.innerHeight;
    let windowWidth = window.innerWidth;

    verySpecificDesign.style.top = `${(windowHeight - height) / 2}px`;
    verySpecificDesign.style.left = `${(windowWidth - width) / 2}px`;

    // setStyle({
    //   transform: `translate(-50%, -50%), scale(${scale})`,
    //   transformOrigin: "top left",
    //   color: "red",
    // });
    // set style to body

    // on resize
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
    // close event listener
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, [windowWidth]);

  return (
    <Provider store={store}>
      <div className="App" ref={verySpecificDesignRef} style={style}>
        <Header setHeaderHeight={setHeaderHeight} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="book" element={<Book />} />
          <Route path="voice" element={<Voice />} />
          <Route path="poem" element={<Poem />} />
          <Route path="author" element={<Author />} />
          <Route path="admin" element={<AdminPage />} />
          <Route path="metadata" element={<MetaDataPage />} />
          <Route path="cartoon" element={<CartoonPage />} />
          <Route path="press" element={<PressPage />} />
        </Routes>
        <Years headerHeight={headerHeight} setYearsWidth={setYearsWidth} />
        <BottomBorder yearsWidth={yearsWidth} headerHeight={headerHeight} />
        {location.pathname === "/" ||
        location.pathname === "/voice" ||
        location.pathname === "/author" ||
        location.pathname === "/about" ? (
          <MetaInspector />
        ) : null}
      </div>
    </Provider>
  );
}

export default App;
