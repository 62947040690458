import React from "react";
import "../admin.css";
import { useDispatch, useSelector } from "react-redux";
import {
  postPoem,
  updatePoem,
  resetCurrent,
} from "../../../redux/slices/poemSlice";
import { getAllPoems } from "../../../redux/slices/searchSlice";
import { setCurrentTab } from "../../../redux/slices/tabsSlice";

const NewBook = () => {
  const dispatch = useDispatch();

  const editMode = useSelector((state) => state.poems.editMode);

  const poem = useSelector((state) => state.poems.currentPoems);

  const [title, setTitle] = React.useState(editMode ? poem.title : "");
  const [text, setText] = React.useState(editMode ? poem.body : "");
  const [year, setYear] = React.useState(editMode ? poem.year : "");
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    if (text) {
      //spliet text by special characters and spaces
      const tags = text.split(/[\s,]+/);

      //remove . ,  from tags
      for (let i = 0; i < tags.length; i++) {
        tags[i] = tags[i].replace(/[^a-zA-Zа-яА-Я0-9]/g, "");
        // to lowercase
        tags[i] = tags[i].toLowerCase();
        // remove empty strings
        if (tags[i] === "") {
          tags.splice(i, 1);
        }
      }

      setTags(tags);
    }
  }, [text]);

  function handleUploadPoem() {
    var poem = {
      title,
      body: text,
      year,
      tags,
    };
    dispatch(postPoem(poem));
    dispatch(setCurrentTab("1"));
  }
  function handleCancelEdit() {
    dispatch(resetCurrent());
    dispatch(setCurrentTab("1"));
  }

  function handleUpdatePoem() {
    var updatedPoem = {
      title,
      body: text,
      year,
      tags,
    };
    dispatch(updatePoem({ uPoem: updatedPoem, id: poem._id }));
    dispatch(getAllPoems());
    dispatch(resetCurrent());
    dispatch(setCurrentTab("1"));
  }

  return (
    <div>
      <p className="text">Название:</p>
      <input
        type="text"
        className="namingInput"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <p className="text">Текст стихотворения</p>
      <textarea
        className="textArea"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <p className="text">Год</p>
      <input
        type="text"
        value={year}
        onChange={(e) => setYear(e.target.value)}
        className="yearInput"
      />
      {editMode ? (
        <div className="btn-container">
          <button className="btn-danger" onClick={() => handleCancelEdit()}>
            Отменить редактирование
          </button>
          <button className="btn-success" onClick={() => handleUpdatePoem()}>
            Сохранить изменении
          </button>
        </div>
      ) : (
        <div className="btn-container">
          {/* <button className="button">Новый</button>  */}
          <button className="btn-success" onClick={() => handleUploadPoem()}>
            Сохранить
          </button>
        </div>
      )}
    </div>
  );
};

export default NewBook;
