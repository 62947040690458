import React from "react";

const questions1 = [
  {
    text: "Ответы автора на вопросы журнала BellaTerra 2010\n",
  },
  {
    text: "\n1. Дайте самой себе краткую характеристику, 5-6 предложений? – чудная девочка! и так 5 - 6 раз.",
  },
  {
    text: "2. Ваше главное достижение в жизни, в творчестве. – дети и… дети",
  },
  {
    text: "3. Что Вам нужно для счастья? – мужчина море и нобелевская премия",
  },
  {
    text: "4. Главное Ваше приобретение благодаря профессии? – способность слышать мир",
  },
  {
    text: "5. Способны ли Вы на безрассудства? – только на них",
  },
  {
    text: "6. Ваш самый большой недостаток. - величина",
  },
  {
    text: "7. Ваше самое большое достоинство. - размер",
  },
  {
    text: "8. Верный способ вывести Вас из себя. – знать дорогу",
  },
  {
    text: "9. Что Вы себе запрещаете? – чту УК(уголовный кодекс)",
  },
  {
    text: "10. Ваша самая большая причуда – вера в светлое будущее",
  },
  {
    text: "11. Перед чем Вы не можете устоять? –  перед стульями, креслами и диванами",
  },
  {
    text: "12. Чего Вы боялись в детстве? – темноты",
  },
  {
    text: "13. Чего Вы боитесь сейчас? - высоты",
  },
  {
    text: "14. Переломный момент в Вашей жизни. - рождение",
  },
  {
    text: "15. Ваше любимое занятие – плавание и скольжение",
  },
  {
    text: "16. Когда Вы позволяете себе соврать? – когда очень просят",
  },
  {
    text: "17. Что Вы больше всего цените в людях? – дусу бессмертную",
  },
  {
    text: "18. Что может поднять Вам настроение? - домкрат",
  },
];

const questions2 = [
  {
    text: "19. Кто Вас восхищает? – люди с короткой шеей",
  },
  {
    text: "20. Что Вас может удержать от воплощения идеи? – ее отсутствие",
  },
  {
    text: "21. Что вам нравится из современного искусства? – музыка дождя",
  },
  {
    text: "22. Вы что-нибудь коллекционируете? – труды праведные",
  },
  {
    text: "23. От чего Вам приходится отказываться ради работы? – от интервью)))",
  },
  {
    text: "25. В какую эпоху Вы хотели бы жить? – в эпоху имени мене",
  },
  {
    text: "26. С кем из поэтов прошлого Вы хотели бы подискутировать? - никаких дискуссий Почему? – хватит уже",
  },
  {
    text: "27. Кого из классиков науки Вы бы хотели видеть у себя? – Бома, Кришнамурти, Эйнштейна, Бехтерева… Почему? – мужики уж больно толковые С чем связан такой выбор? с временем суток",
  },
  {
    text: "28. Писать стихи – это дар, счастье или наказание? – это связь с господом по прямому правительственному телефону.",
  },
  {
    text: "29. Когда Вам лучше всего работается? – когда платят.",
  },
  {
    text: "30. Каким еще талантом Вы хотели бы обладать? – еще???",
  },

  {
    text: "\ninikfinik@gmail.com",
  },
  {
    text: "whatsapp: +79253089909",
  },
  {
    text: "TG: +998998642307",
  },
];

const Page = (props) => {
  return (
    <div className={props.class === "left" ? "page left" : "page right"}>
      <p className={props.class === "left" ? "leftTitle" : "rightTitle"}>
        об авторе
      </p>
      {props.data.map((item) => (
        <p className="pageBody pageBody-author">{item.text}</p>
      ))}
      <div className="pageFooter">{props.class === "left" ? 1 : 2}</div>
    </div>
  );
};

export default function About() {
  return (
    <div className="swiper">
      <Page class={"left"} data={questions1} />
      <Page class={"right"} data={questions2} />
    </div>
  );
}
