export const links = [
  {
    title: "шредингер",
    file: "001. шредингер.mp3",
  },
  {
    title: "затраты",
    file: "002. затраты.mp3",
  },
  {
    title: "гора монсерат",
    file: "003. гора монсерат.mp3",
  },
  {
    title: "нельзя",
    file: "004. нельзя.mp3",
  },
  {
    title: "смятение",
    file: "005. смятение.mp3",
  },
  {
    title: "иероглиф",
    file: "006. иероглиф.mp3",
  },
  {
    title: "керосинка",
    file: "007. керосинка.mp3",
  },
  {
    title: "любовь",
    file: "008. любовь.mp3",
  },
  {
    title: "я совершенно беззаботна",
    file: "009. я совершенно беззаботна.mp3",
  },
  {
    title: "изобретение",
    file: "010. изобретение.mp3",
  },
  {
    title: "новый стиль",
    file: "011. новый стиль.mp3",
  },
  {
    title: "дао любящих",
    file: "012. дао любящих.mp3",
  },
  {
    title: "как я люблю",
    file: "013. как я люблю.mp3",
  },
  {
    title: "надземнные коммуникации",
    file: "014. надземнные коммуникации.mp3",
  },
  {
    title: "трансвозвеститское",
    file: "015. трансвозвеститское.mp3",
  },
  {
    title: "до сих пор",
    file: "016. до сих пор.mp3",
  },
  {
    title: "ужасный город",
    file: "017. ужасный город.mp3",
  },
  {
    title: "шум арыка",
    file: "018. шум арыка.mp3",
  },
  {
    title: "я хотела бы",
    file: "019. я хотела бы.mp3",
  },
  {
    title: "мой хадж",
    file: "020. мой хадж.mp3",
  },
  {
    title: "форма",
    file: "021. форма.mp3",
  },
  {
    title: "чтоб вернуться",
    file: "022. чтоб вернуться.mp3",
  },
  {
    title: "за три моря в шопинг",
    file: "023. за три моря в шопинг.mp3",
  },
  {
    title: "война",
    file: "024. война.mp3",
  },
  {
    title: "усталые святые",
    file: "025. усталые святые.mp3",
  },
  {
    title: "отложенный эффект",
    file: "026. отложенный эффект.mp3",
  },
  {
    title: "про погоду",
    file: "027. про погоду.mp3",
  },
  {
    title: "мария",
    file: "028. мария.mp3",
  },
  {
    title: "от руки",
    file: "029. от руки.mp3",
  },
  {
    title: "все сделаю",
    file: "030. все сделаю.mp3",
  },
  {
    title: "сиви",
    file: "031. сиви.mp3",
  },
  {
    title: "дорогое",
    file: "032. дорогое.mp3",
  },
  {
    title: "твою мать",
    file: "033. твою мать.mp3",
  },
  {
    title: "философия крика",
    file: "034. философия крика.mp3",
  },
  {
    title: "в стороне",
    file: "035. в стороне.mp3",
  },
  {
    title: "эпоха зрячих",
    file: "036. эпоха зрячих.mp3",
  },
  {
    title: "под нож",
    file: "037. под нож.mp3",
  },
  {
    title: "сделано",
    file: "038. сделанно.mp3",
  },
  {
    title: "переживаю",
    file: "039. переживаю.mp3",
  },
  {
    title: "что вызывает беспокойство",
    file: "040. что вызывает беспокойство.mp3",
  },
  {
    title: "я есть",
    file: "041. я есть.mp3",
  },
  {
    title: "головоломка",
    file: "042. головоломка.mp3",
  },
  {
    title: "авторское право",
    file: "043. авторское право.mp3",
  },
  {
    title: "поляна",
    file: "044. поляна.mp3",
  },
  {
    title: "красота",
    file: "045. красота.mp3",
  },
  {
    title: "расстояние",
    file: "046. расстояние.mp3",
  },
  {
    title: "поиск истины",
    file: "047. поиск истины.mp3",
  },
  {
    title: "атлант",
    file: "048. атлант.mp3",
  },
  {
    title: "греческий профиль",
    file: "049. греческий профиль.mp3",
  },
  {
    title: "искусственный горизонт",
    file: "050. artificial horizon.mp3",
  },
  {
    title: "экзорцизм",
    file: "051. экзорцизм.mp3",
  },
  {
    title: "сказ",
    file: "052. сказ.mp3",
  },
  {
    title: "дело",
    file: "053. дело.mp3",
  },
  {
    title: "йод в молоке",
    file: "054. йод в молоке.mp3",
  },
  {
    title: "невыносимо",
    file: "055. невыносимо.mp3",
  },
  {
    title: "не опоздайте",
    file: "056. не опоздайте.mp3",
  },
  {
    title: "пугающее тело",
    file: "057. пугающее тело.mp3",
  },
  {
    title: "хочу глагол",
    file: "058. хочу глагол.mp3",
  },
  {
    title: "в широких штанинах",
    file: "059. в широких штанинах.mp3",
  },
  {
    title: "увертюра на стройплощадке",
    file: "060. увертюра на стройплощадке.mp3",
  },
  {
    title: "прятки",
    file: "061. прятки.mp3",
  },
  {
    title: "напалм",
    file: "062. напалм.mp3",
  },
  {
    title: "на смерть врага",
    file: "063. на смерть врага.mp3",
  },
  {
    title: "сейчас",
    file: "064. сейчас.mp3",
  },
  {
    title: "по взрослому",
    file: "065. по взрослому.mp3",
  },
  {
    title: "полет идет нормально",
    file: "066. полет идет нормально.mp3",
  },
  {
    title: "неизвестность",
    file: "067. неизвестность.mp3",
  },
  {
    title: "кресло качалка",
    file: "068. кресло качалка.mp3",
  },
  {
    title: "фата моргана",
    file: "069. фата моргана.mp3",
  },
  {
    title: "пых",
    file: "071. пых.mp3",
  },
  {
    title: "ветвеподобные",
    file: "072. ветвеподобные.mp3",
  },
  {
    title: "кусок смолы",
    file: "073. кусок смолы.mp3",
  },
  {
    title: "романсики",
    file: "074. романсики.mp3",
  },
  {
    title: "плюс минус",
    file: "075. плюс минус.mp3",
  },
  {
    title: "небольшое горе",
    file: "076. небольшое горе.mp3",
  },
  {
    title: "когда наконец",
    file: "077. когда наконец.mp3",
  },
  {
    title: "спасение утопающих",
    file: "078. спасение утопающих.mp3",
  },
  {
    title: "царевна лягушка",
    file: "079. царевна лягушка.mp3",
  },
  {
    title: "без денег",
    file: "080. без денег.mp3",
  },
  {
    title: "исключено",
    file: "081. исключенно.mp3",
  },
  {
    title: "а вас я не прошу остаться",
    file: "082. а вас я не прошу остаться.mp3",
  },
  {
    title: "секс в маленьком городе",
    file: "083. секс в маленьком городе.mp3",
  },
  {
    title: "поперек ветра в поле",
    file: "084. поперек ветра в поле.mp3",
  },
  {
    title: "будем мешать",
    file: "085. будем мешать.mp3",
  },
  {
    title: "иногда",
    file: "086. иногда.mp3",
  },
  {
    title: "так было всегда",
    file: "087. так было всегда.mp3",
  },
  {
    title: "причина многословия",
    file: "088. причина многословия.mp3",
  },
  {
    title: "жизнь",
    file: "089. жизнь.mp3",
  },
  {
    title: "требование времени",
    file: "090. требование времени.mp3",
  },
  {
    title: "чем вы занимаетесь",
    file: "091. чем вы занимаетесь.mp3",
  },
  {
    title: "в анамназе",
    file: "092. в анамназе.mp3",
  },
  {
    title: "я стала",
    file: "093. я стала.mp3",
  },
  {
    title: "из за такта",
    file: "094. из за такта.mp3",
  },
  {
    title: "любовь",
    file: "095. любовь.mp3",
  },
  {
    title: "пазл",
    file: "096. пазл.mp3",
  },
  {
    title: "талант",
    file: "097. талант.mp3",
  },
  {
    title: "скетч",
    file: "098. скетч.mp3",
  },
  {
    title: "философский камень",
    file: "098. филосовский камень.mp3",
  },
  {
    title: "глухомань",
    file: "099. глухомань.mp3",
  },
  {
    title: "с новым 2019",
    file: "100. с новым 2019.mp3",
  },
  {
    title: "синдром отложенной жизни",
    file: "101. синдром отложенной жизни.mp3",
  },
  {
    title: "хочу спать",
    file: "102. хочу спать.mp3",
  },
  {
    title: "о ценностях",
    file: "103. о ценностях.mp3",
  },
  {
    title: " в угол",
    file: "104.  в угол.mp3",
  },
  {
    title: "беcсонная депривация",
    file: "105. беcсонная депривация.mp3",
  },
  {
    title: "граница встречи",
    file: "106. граница встречи.mp3",
  },
  {
    title: " духовный закон",
    file: "107.  духовный закон.mp3",
  },
  {
    title: "ризома 85",
    file: "108. ризома 85.mp3",
  },
  {
    title: "как стать",
    file: "109. как стать.mp3",
  },
  {
    title: "утро сборы",
    file: "110. утро сборы.mp3",
  },
  {
    title: "дождь",
    file: "111. дождь.mp3",
  },
  {
    title: "скрижали",
    file: "112. скрижали.mp3",
  },
  {
    title: "new year eve",
    file: "113. new year eve.mp3",
  },
  {
    title: "наружу",
    file: "114. наружу.mp3",
  },
  {
    title: "игра",
    file: "115. игра.mp3",
  },
  {
    title: "конец фильма",
    file: "116. конец фильма.mp3",
  },
  {
    title: "ламентации по оставшимся зубам",
    file: "117. ламентации по оставшимся зубам.mp3",
  },
  {
    title: "черная корова",
    file: "118. черная корова.mp3",
  },
  {
    title: "пара фраз",
    file: "119. пара фраз.mp3",
  },
  {
    title: "лица",
    file: "120. лица.mp3",
  },
  {
    title: "ризома",
    file: "121. ризома.mp3",
  },
  {
    title: "кто я",
    file: "122. кто я.mp3",
  },
  {
    title: "фра мауро",
    file: "123. фра мауро.mp3",
  },
  {
    title: "мосхозторг отдел ниток",
    file: "124. мосхозторг отдел ниток.mp3",
  },
  {
    title: "получилось",
    file: "125. получилось.mp3",
  },
  {
    title: "пространство тишины",
    file: "126. пространство тишины.mp3",
  },
  {
    title: "про это",
    file: "127. про это.mp3",
  },
  {
    title: "безбожники",
    file: "128. безбожники.mp3",
  },
  {
    title: "тюрьма для всех",
    file: "129. тюрьма для всех.mp3",
  },
  {
    title: "маловато будет",
    file: "130. маловато будет.mp3",
  },
  {
    title: "вместо зеркала",
    file: "131. вместо зеркала.mp3",
  },
  {
    title: "усталость в ноябре",
    file: "132. усталость в ноябре.mp3",
  },
  {
    title: "концерт",
    file: "133. концерт.mp3",
  },
  {
    title: "советская песенка",
    file: "134. советская песенка.mp3",
  },
  {
    title: "инсайт",
    file: "135. инсайт.mp3",
  },
  {
    title: "бутылочка",
    file: "136. бутылочка.mp3",
  },
  {
    title: "работа.ру",
    file: "137. работа.ру.mp3",
  },
  {
    title: "он и она",
    file: "138. он и она.mp3",
  },
  {
    title: "никогда",
    file: "139. никогда.mp3",
  },
  {
    title: "арал",
    file: "140. арал.mp3",
  },
  {
    title: "куда что девается",
    file: "141. куда что девается.mp3",
  },
  {
    title: "прививка от расстрела",
    file: "142. прививка от расстрела.mp3",
  },
  {
    title: "поразительно",
    file: "143. поразительно.mp3",
  },
  {
    title: "зачем то",
    file: "144. зачем то.mp3",
  },
  {
    title: "язычники",
    file: "145. язычники.mp3",
  },
  {
    title: "немигрант",
    file: "146. немигрант.mp3",
  },
  {
    title: "видос",
    file: "147. видос.mp3",
  },
  {
    title: "лавровый лист",
    file: "148. лавровый лист.mp3",
  },
  {
    title: "победы современного законотворчества",
    file: "149. победы современного законотворчества.mp3",
  },
  {
    title: "круговорот",
    file: "150. круговорот.mp3",
  },
  {
    title: "технологии",
    file: "151. технологии.mp3",
  },
  {
    title: "брандспойт",
    file: "152. брандспойт.mp3",
  },
  {
    title: "песни хоть тресни",
    file: "153. песни хоть тресни.mp3",
  },
  {
    title: "кино",
    file: "154. кино.mp3",
  },
  {
    title: "скоро уже",
    file: "155. скоро уже.mp3",
  },
  {
    title: "бах",
    file: "156. бах.mp3",
  },
  {
    title: "поетические пати",
    file: "157. поетические пати.mp3",
  },
  {
    title: "согласие",
    file: "158. согласие.mp3",
  },
  {
    title: "городская сумасшедшая",
    file: "159. городская сумасшедшая.mp3",
  },
  {
    title: "прямохождение",
    file: "160. прямохождение.mp3",
  },
  {
    title: "один ноль бум",
    file: "161. один ноль бум.mp3",
  },
  {
    title: "предназначение",
    file: "162. предназначение.mp3",
  },
  {
    title: "а может быть корова",
    file: "163. а может быть корова.mp3",
  },
  {
    title: "слышать слово",
    file: "164. слышать слово.mp3",
  },
  {
    title: "синяк",
    file: "165. синяк.mp3",
  },
  {
    title: "алоха",
    file: "166. алоха.mp3",
  },
  {
    title: "полный пиздец",
    file: "167. полный пиздец.mp3",
  },
  {
    title: "отчет",
    file: "168. отчет.mp3",
  },
  {
    title: "уверянность в себе",
    file: "169. уверянность в себе.mp3",
  },
  {
    title: "творю",
    file: "170. творю.mp3",
  },
  {
    title: "никому",
    file: "171. никому.mp3",
  },
  {
    title: "конфессиональный срок",
    file: "172. конфессиональный срок.mp3",
  },
  {
    title: "из анекдота",
    file: "173. из анекдота.mp3",
  },
  {
    title: "обратный солипсизм",
    file: "174. обратный солипсизм.mp3",
  },
  {
    title: "амбивалентность",
    file: "175. амбивалентность.mp3",
  },
  {
    title: "о драме",
    file: "176. о драме.mp3",
  },
  {
    title: "мысли о числах",
    file: "177. мысли о числах.mp3",
  },
  {
    title: "по нашему",
    file: "178. по нашему.mp3",
  },
  {
    title: "грущу",
    file: "179. грущу.mp3",
  },
  {
    title: "публичные высказывания",
    file: "180. публичные высказывания.mp3",
  },
  {
    title: "живопись",
    file: "181. живопись.mp3",
  },
  {
    title: "репорт",
    file: "182. репорт.mp3",
  },
  {
    title: "анн нет",
    file: "183. анн нет.mp3",
  },
  {
    title: "слушаю",
    file: "184. слушаю.mp3",
  },
  {
    title: "стараюсь",
    file: "185. стараюсь.mp3",
  },
  {
    title: "отражение слов",
    file: "186. отражение слов.mp3",
  },
  {
    title: "работа",
    file: "187. работа.mp3",
  },
  {
    title: "закавыка",
    file: "188. закавыка.mp3",
  },
  {
    title: "чужая смерть",
    file: "189. чужая смерть.mp3",
  },
  {
    title: "чувство времени",
    file: "189. чувство времени.mp3",
  },
  {
    title: "не молчать",
    file: "190. не молчать.mp3",
  },
  {
    title: "русский характер",
    file: "191. русский характер.mp3",
  },
  {
    title: "женщины птицы",
    file: "192. женщины птицы.mp3",
  },
  {
    title: "собственность",
    file: "193. собственность.mp3",
  },
  {
    title: "никак",
    file: "194. никак.mp3",
  },
  {
    title: "кажется",
    file: "195. кажется.mp3",
  },
  {
    title: "века",
    file: "196. века.mp3",
  },
  {
    title: "девятый шквал",
    file: "196. девятый шквал.mp3",
  },
  {
    title: "стою пою",
    file: "197. стою пою.mp3",
  },
  {
    title: "сталистое",
    file: "198. сталистое.mp3",
  },
  {
    title: "на чердаке",
    file: "199. на чердаке.mp3",
  },
  {
    title: "как дым",
    file: "200. как дым.mp3",
  },
  {
    title: "восприятие",
    file: "201. восприятие.mp3",
  },
  {
    title: "сто страниц",
    file: "202. сто страниц.mp3",
  },
  {
    title: "прогресс",
    file: "203. прогресс.mp3",
  },
  {
    title: "следую",
    file: "204. следую.mp3",
  },
  {
    title: "слово",
    file: "205. слово.mp3",
  },
  {
    title: "просьба",
    file: "206. просьба.mp3",
  },
  {
    title: "план",
    file: "207. план.mp3",
  },
  {
    title: "я часть",
    file: "208. я часть.mp3",
  },
  {
    title: "иногда",
    file: "209. иногда.mp3",
  },
  {
    title: "все об одном",
    file: "210. все об одном.mp3",
  },
  {
    title: "там там тарам",
    file: "211. там там тарам.mp3",
  },
  {
    title: "я виновата",
    file: "212. я виновата.mp3",
  },
  {
    title: "без борьбы",
    file: "213. без борьбы.mp3",
  },
  {
    title: "я не грущу",
    file: "214. я не грущу.mp3",
  },
  {
    title: "заповеданное",
    file: "215. заповеданное.mp3",
  },
  {
    title: "наш почерк",
    file: "216. наш почерк.mp3",
  },
  {
    title: "непорядок",
    file: "217. непорядок.mp3",
  },
  {
    title: "шумлю",
    file: "218. шумлю.mp3",
  },
  {
    title: "фейсбук о чем вы думаете",
    file: "219. фейсбук о чем вы думаете.mp3",
  },
  {
    title: "чистая идея",
    file: "220. чистая идея.mp3",
  },
  {
    title: "воскресная уборка",
    file: "221. воскресная уборка.mp3",
  },
  {
    title: "морзянка страсти",
    file: "222. морзянка страсти.mp3",
  },
  {
    title: "икар",
    file: "223. икар.mp3",
  },
  {
    title: "сватовство майоры",
    file: "224. сватовство майоры.mp3",
  },
  {
    title: "забота",
    file: "225. забота.mp3",
  },
  {
    title: "вопросы веры",
    file: "226. вопросы веры.mp3",
  },
  {
    title: "сижу дышу",
    file: "227. сижу дышу.mp3",
  },
  {
    title: "шоссе",
    file: "228. шоссе.mp3",
  },
  {
    title: "спортивный интерес",
    file: "229. спортивный интерес.mp3",
  },
  {
    title: "свобода пуще неволи",
    file: "230. свобода пуще неволи.mp3",
  },
  {
    title: "предупреждение",
    file: "231. предупреждение.mp3",
  },
  {
    title: "помошник кассира",
    file: "232. помошник кассира.mp3",
  },
  {
    title: "в пустыне",
    file: "233. в пустыне.mp3",
  },
  {
    title: "цинтскаро",
    file: "234. цинтскаро.mp3",
  },
  {
    title: "иду иду",
    file: "235. иду иду.mp3",
  },
  {
    title: "бытие мое",
    file: "236. бытие мое.mp3",
  },
  {
    title: "в черном",
    file: "237. в черном.mp3",
  },
  {
    title: "дым",
    file: "238. дым.mp3",
  },
  {
    title: "отшельник",
    file: "239. отшельник.mp3",
  },
  {
    title: "на скрижалях",
    file: "240. на скрижалях.mp3",
  },
  {
    title: "на крупных планах",
    file: "241. на крупных планах.mp3",
  },
  {
    title: "сезонное",
    file: "242. сезонное.mp3",
  },
  {
    title: "веяние времени",
    file: "243. веяние времени.mp3",
  },
  {
    title: "переключитель",
    file: "244. переключитель.mp3",
  },
  {
    title: "пересчитала",
    file: "245. пересчитала.mp3",
  },
  {
    title: "социализация",
    file: "246. социализация.mp3",
  },
  {
    title: "обратка",
    file: "247. обратка.mp3",
  },
  {
    title: "растерянность",
    file: "248. растерянность.mp3",
  },
  {
    title: "экспромпт",
    file: "249. экспромпт.mp3",
  },
  {
    title: "астронавты",
    file: "250. астронавты.mp3",
  },
  {
    title: "результат",
    file: "251. результат.mp3",
  },
  {
    title: "как это делается",
    file: "252. как это делается.mp3",
  },
  {
    title: "геолокация",
    file: "253. геолокация.mp3",
  },
  {
    title: "кажется",
    file: "254. кажется.mp3",
  },
  {
    title: "награда",
    file: "255. награда.mp3",
  },
  {
    title: "авторская логика",
    file: "256. авторская логика.mp3",
  },
  {
    title: "декларация",
    file: "257. декларация.mp3",
  },
  {
    title: "акцент",
    file: "258. акцент.mp3",
  },
  {
    title: "лайфхак",
    file: "259. лайфхак.mp3",
  },
  {
    title: "мечта бескрылая приземленная",
    file: "260. мечта бескрылая приземленная.mp3",
  },
  {
    title: "важно",
    file: "261. важно.mp3",
  },
  {
    title: "пора мне ехать",
    file: "262. пора мне ехать.mp3",
  },
  {
    title: "про рай",
    file: "263. про рай.mp3",
  },
  {
    title: "иероглифы",
    file: "264. иероглифы.mp3",
  },
  {
    title: "было",
    file: "265. было.mp3",
  },
  {
    title: "ихтоя",
    file: "266. ихтоя.mp3",
  },
  {
    title: "мифы древней",
    file: "267. мифы древней.mp3",
  },
  {
    title: "погода.ру",
    file: "268. погода.ру.mp3",
  },
  {
    title: "когда находит стих",
    file: "269. когда находит стих.mp3",
  },
  {
    title: "про про про",
    file: "270. про про про.mp3",
  },
  {
    title: "рентожизнь",
    file: "271. рентожизнь.mp3",
  },
  {
    title: "не там",
    file: "272. не там.mp3",
  },
  {
    title: "честно",
    file: "273. честно.mp3",
  },
  {
    title: "вол март",
    file: "274. вол март.mp3",
  },
  {
    title: "молчи",
    file: "275. молчи.mp3",
  },
  {
    title: "ангел лжи",
    file: "276. ангел лжи.mp3",
  },
  {
    title: "вандализм",
    file: "277. вандализм.mp3",
  },
  {
    title: "мачо пикчо",
    file: "278. мачо пикчо.mp3",
  },
  {
    title: "давненько",
    file: "279. давненько.mp3",
  },
  {
    title: "порядок действий",
    file: "280. порядок действий.mp3",
  },
  {
    title: "где бы осесть",
    file: "281. где бы осесть.mp3",
  },
  {
    title: "упираюсь",
    file: "282. упираюсь.mp3",
  },
  {
    title: "не там",
    file: "283. не там.mp3",
  },
  {
    title: "мини манифест",
    file: "284. мини манифест.mp3",
  },
  {
    title: "путь домой",
    file: "285. путь домой.mp3",
  },
  {
    title: "белое на белом",
    file: "286. белое на белом.mp3",
  },
];
