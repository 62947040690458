import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deletePoem,
  openModal,
  handleSelectedPoem,
  setDeleteDialog,
} from "../../../redux/slices/poemSlice";
import { removePoem } from "../../../redux/slices/searchSlice";

export default function PoemsTable({ poems, handleSetPoem, number }) {
  const { title, year, body, _id, index, tags } = poems;
  const dispatch = useDispatch();

  function handleRemove(id) {
    dispatch(deletePoem(_id));
    dispatch(removePoem(_id));
  }

  return (
    <>
      <tr>
        <td>{number + 1}</td>
        <td>{title}</td>
        <td>{tags.length}</td>
        <td>{year}</td>
        <td>
          <div className="action-buttons">
            <button
              className="btn-primary"
              onClick={() => handleSetPoem(poems)}
            >
              Редактировать
            </button>
            <button
              className="btn-danger"
              onClick={() => dispatch(setDeleteDialog(poems))}
            >
              {/* <button className="btn-danger" onClick={() => handleRemove(_id)}> */}
              Удалить
            </button>
            <button
              className="btn-success"
              onClick={() => dispatch(openModal(poems))}
            >
              Посмотреть текст
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}
