// tab components

import Names from "./adminItems/Names";
import NewBook from "./adminItems/NewBook";
import BlockedWords from "./adminItems/BlockedWords";
import UsedList from "./adminItems/UsedList";
// import { Item } from "react-bootstrap/lib/breadcrumb";

export const tabs = [
  {
    id: 1,
    tabTitle: "Список стихов",
    title:
      "Список названий стихотворений и количество слов в каждом. Переход на редактирование происходит кликом на название. Названия отсортированы по дате редактирования",
    content: <Names />,
  },
  {
    id: 2,
    tabTitle: "Новое стихотворение",
    title:
      "Ввод, редактирование, удаление стихотворения. При сохранении/удалении обновляется БД используемых слов.",
    content: <NewBook />,
  },
  {
    id: 3,
    tabTitle: "Список использованных слов",
    title:
      "Первые 400 слов, используемые в стихотворениях и отсортированные по количеству их появления в тексте стихов. В число скобках - количество появления данного слова в стихах.",
    content: <UsedList />,
  },
  {
    id: 4,
    tabTitle: "Блокированные слова",
    title:
      "Слова, которые не участвуют в подсчете используемых слов. Кликнув на слово его можно удалить из списка",
    content: <BlockedWords />,
  },
];
