import React, { useEffect, useState } from "react";
// react-redux
import { useSelector } from "react-redux";
// swiper-js
import { Swiper, SwiperSlide } from "swiper/react";
// copy to clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
// import required modules
import { EffectCreative } from "swiper";
import MeatDataItem from "./MeatDataItem";
// css
import "./metadata.css";
import "../voice/voice.css";

const Page = (props) => {
  return (
    <div className={props.class === "left" ? "page left" : "page right"}>
      <p className={props.class === "left" ? "leftTitle" : "rightTitle"}>
        метадата
      </p>
      <div className="metadata">
        {props.metaDataArray.map((item, i) => (
          <MeatDataItem item={item} index={i} key={i} />
        ))}
      </div>

      <div className="pageFooter">{props.number}</div>
    </div>
  );
};

export default function MetaData() {
  const historyApi = useSelector((state) => state.poems.history);
  const maxLines = useSelector((state) => state.poems.maxLines);
  const windowHeigth = window.innerHeight;
  // let location = useLocation();
  // console.log("Location", window.location.hostname);
  const [history, sethistory] = useState([]);

  function convertArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  useEffect(() => {
    sethistory(
      convertArray(historyApi, windowHeigth <= 817 ? maxLines / 2 : maxLines)
    );
  }, [historyApi, windowHeigth]);

  return (
    <div className="flip-book">
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
      >
        {history.map((item, index) => {
          if (index % 2 === 0) {
            return (
              <SwiperSlide key={index}>
                <Page number={index + 1} class={"left"} metaDataArray={item} />
                {history[index + 1] !== undefined ? (
                  <Page
                    number={index + 2}
                    class={"right"}
                    metaDataArray={history[index + 1]}
                  />
                ) : (
                  <Page number={index + 2} class={"right"} metaDataArray={[]} />
                )}
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
    </div>
  );
}
