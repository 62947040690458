import React from "react";
// css
import "./about.css";

const Page = (props) => {
  return (
    <div className={props.class === "left" ? "page left" : "page right"}>
      <p className={props.class === "left" ? "leftTitle" : "rightTitle"}>
        о проекте
      </p>
      <p class="pageTitle">{props.title}</p>
      <span className="epigraph">{props.epigraph}</span>

      <p className="pageBody about">{props.text}</p>
      <div className="pageFooter">{props.class === "left" ? 1 : 2}</div>
    </div>
  );
};

export default function About() {
  return (
    <div className="swiper">
      <Page
        class={"left"}
        title={"необходимое предисловие"}
        epigraph={
          "за стойкость не следует требовать награды, стойкость уже и есть награда.  У. Фолкнер "
        }
        text={`я пишу стихи собираю их в книжки 
и жду дня
когда спустившись в метро
обнаружу эти книжки раскрытыми
"на самом интересном месте"
в руках у каждого второго 
сидящего в вагоне
как минимум
пока в метро не езжу 
что бы не расстраиваться
считаю преждевременным
это паранойя 
но не графомания
я пишу только о себе 
но в тайне надеюсь 
что баланс работы 
сознания и подсознания читающих 
настолько высок и точен 
что им и о них самих 
тоже будет интересно
ибо паранойя летуча
как все живое
а может живуча

`}
      />
      <Page
        class={"right"}
        text={`как все летучее
1999 год
чтоб не попасть 
в книгу рекордов гиннеса 
за любовь к собственному творчеству 
книгоиздательство прекратила
и тридцать лет писала 
сперва в стол а затем в файл
пока те кому когда то дарила книги
не начали повторять мантру 
отдайте стихи людям
хотя это дело издателя а не поэта
до фамилии которого в моем 
отсроченном алфавитом случае 
руки могут так и не дойти
поэтому сделала сайт 
где каждый может 
нажать на любое слово 
плавающее в облаке тэгов
и получив книгу в которой собраны 
все стихотворения с этим словом 
стать издателем 
и даже ею поделиться
благо авторство уже указано
самообслуживание господа люди!
2022 год`}
      />
    </div>
  );
}
