import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const token = localStorage.getItem("token") || "";
const BASE_URL = "https://node.inesse.uz";
// const BASE_URL = "http://localhost:4000";

export const getPoemsByTag = createAsyncThunk(
  "poem/getPoemsByTag",
  async (tag) => {
    const response = await axios.get(`${BASE_URL}/api/poem/tag/${tag}`);
    return response.data;
  }
);

export const getPoemsByYear = createAsyncThunk(
  "poem/getPoemsByYear",
  async (year) => {
    const response = await axios.get(`${BASE_URL}/api/poem/year/${year}`);
    return response.data;
  }
);

export const postPoem = createAsyncThunk("poem/postPoem", async (poem) => {
  const response = await axios.post(`${BASE_URL}/api/poem/add`, poem);
  return response.data;
});

export const updatePoem = createAsyncThunk("poem/updatePoem", async (data) => {
  const response = await axios.put(
    `${BASE_URL}/api/poem/edit/${data.id}`,
    data.uPoem
  );
  return response.data;
});

export async function sendHistory(data) {
  const response = await axios.post(`${BASE_URL}/api/history`, data);
  return response;
}

// export const getHistory = createAsyncThunk("history/getHistory", async () => {
//   const response = await axios.get(`${BASE_URL}/api/history`);
//   return response.data;
// });

export const deletePoem = createAsyncThunk(
  `${BASE_URL}/poem/deletePoem`,
  async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.delete(
      `${BASE_URL}/api/poem/poem-detail/${id}`,
      config
    );
    return response.data;
  }
);

// cuts poems by maxLines
function cutPoem(poems, maxLines) {
  let arr = [...poems];
  arr.map((poem, i) => {
    let lines = poem.body.split(/(?:\r\n|\r|\n)/g).length - 1;
    if (lines > maxLines) {
      // arr.splice(i + 1, 0, { body: "", title: poem.title });

      // create 2 new arrays  after 16 lines
      let first = poem.body.split(/(?:\r\n|\r|\n)/g).slice(0, maxLines);
      let second = poem.body.split(/(?:\r\n|\r|\n)/g).slice(maxLines);

      first = first.map((line) => {
        return line + "\n";
      });
      second = second.map((line) => {
        return line + "\n";
      });

      poem.body = first.join("");

      arr.splice(i + 1, 0, { body: second.join("") });
    }
  });
  return arr;
}

const poemsSlice = createSlice({
  name: "poemsSlice",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    editMode: false,
    poems: [],
    currentPoems: {},
    textModal: false,
    selectedPoem: {},
    deleteDialog: false,
    deletingPoem: {},
    history: [],
    poemsLength: 0,
    maxLines: 25,
  },
  reducers: {
    setPoems: (state, action) => {
      state.poems = action.payload;
    },
    setCurrentPoem: (state, action) => {
      state.editMode = true;
      state.currentPoems = action.payload;
    },
    clearPoems: (state) => {
      state.poems = [];
    },
    resetCurrent: (state) => {
      state.currentPoems = {};
      state.editMode = false;
    },
    openModal: (state, action) => {
      state.textModal = !state.textModal;
      state.selectedPoem = action.payload;
    },
    setDeleteDialog: (state, action) => {
      state.deleteDialog = !state.deleteDialog;
      state.deletingPoem = action.payload;
    },
    setMaxLines: (state, action) => {
      state.maxLines = action.payload;
    },
    handleSendHistory: (state, action) => {
      state.history.push({
        ...action.payload,
        poemsCount: state.poemsLength,
        createdAt: new Date(),
      });
      localStorage.setItem("history", JSON.stringify(state.history));
    },
    getHistory: (state, action) => {
      state.history = JSON.parse(localStorage.getItem("history")) || [];
      console.log(JSON.parse(localStorage.getItem("history")));
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getPoemsByTag.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPoemsByTag.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.poemsLength = action.payload.length;
        state.poems = cutPoem(action.payload, state.maxLines);
        state.isLoading = false;
      })
      .addCase(getPoemsByTag.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getPoemsByYear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPoemsByYear.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.poemsLength = action.payload.length;
        state.poems = cutPoem(action.payload, state.maxLines);
      })
      .addCase(getPoemsByYear.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(postPoem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postPoem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(postPoem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updatePoem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePoem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updatePoem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
    // .addCase(getHistory.pending, (state) => {
    //   state.isLoading = true;
    // })
    // .addCase(getHistory.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = true;
    //   state.history = action.payload;
    // })
    // .addCase(getHistory.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.isError = true;
    //   console.log(action.error);
    // });
  },
});

export const {
  setPoems,
  setCurrentPoem,
  clearPoems,
  resetCurrent,
  openModal,
  handleSelectedPoem,
  setDeleteDialog,
  setMaxLines,
  handleSendHistory,
  getHistory,
} = poemsSlice.actions;

export default poemsSlice.reducer;
