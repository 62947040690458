import { useState, useEffect } from "react";
// react-redux
import { useDispatch, useSelector } from "react-redux";
import { loginUser, getProfile } from "../../redux/slices/userSlice";
// css
import "./login.css";

export default function Login() {
  const dispatch = useDispatch(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function sendLogin(email, password) {
    dispatch(loginUser({ email, password }));
  }
  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    dispatch(getProfile(token));
  }, []);

  return (
    <div className="login-box">
      <h2>Вход</h2>
      <form>
        <div className="user-box">
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Логин</label>
        </div>
        <div className="user-box">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label>Пароль</label>
        </div>
        <a href="#" onClick={() => sendLogin(email, password)}>
          Войти
        </a>
      </form>
    </div>
  );
}
