import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// create searchPoemQuery async thunk and send data in body with get req
const BASE_URL = "https://node.inesse.uz";

export const searchPoemQuery = createAsyncThunk(
  "search/searchPoemQuery",
  async (data) => {
    const { data: poems } = await axios.get(`${BASE_URL}/api/poem/query`, {
      params: data,
    });
    return poems;
  }
);

export const getAllPoems = createAsyncThunk("search/getAllPoems", async () => {
  const { data: poems } = await axios.get(`${BASE_URL}/api/poem/add`);
  return poems;
});

const searchSlice = createSlice({
  name: "searchSlice",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    allWordsCount: 0,
    data: [],
  },
  reducers: {
    addToken: (state) => {
      localStorage.setItem("token", state.data?.token);
    },
    removeToken: () => {
      localStorage.removeItem("token");
    },
    removePoem: (state, action) => {
      state.data = state.data.filter(
        (element) => element._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(searchPoemQuery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchPoemQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // reverse array to show newest poems first
        state.data = action.payload.reverse();
      })
      .addCase(searchPoemQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.data = {};
      })
      .addCase(getAllPoems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllPoems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload.reverse();

        // code under counting ALL tags in DB

        let allTags = [];

        action.payload.forEach((poem) => {
          const tags = poem.body.split(/[\s,]+/);

          //remove . ,  from tags
          for (let i = 0; i < tags.length; i++) {
            tags[i] = tags[i].replace(/[^a-zA-Zа-яА-Я0-9]/g, "");
            // to lowercase
            tags[i] = tags[i].toLowerCase();
            // remove empty strings
            if (tags[i] === "") {
              tags.splice(i, 1);
            }

            allTags.push(tags[i]);
          }
        });

        state.allWordsCount = allTags.length;
      })
      .addCase(getAllPoems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.data = {};
      });
  },
});

export const { openModal, closeModal, logOut, removePoem } =
  searchSlice.actions;

export default searchSlice.reducer;
