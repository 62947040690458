import React, { useRef, useEffect, useState } from "react";
// react-redux
import { useDispatch, useSelector } from "react-redux";
import { getPoemsByTag, clearPoems } from "../../redux/slices/poemSlice";
import {
  setCurrentWord,
  resetCurrentWord,
  resetCurrentYear,
} from "../../redux/slices/wordSlice";
import { setMaxLines, getHistory } from "../../redux/slices/poemSlice";
// react-router-dom
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getBlockedWords } from "../../redux/slices/wordSlice";
import { setHeaderHeightRedux } from "../../redux/slices/layoutSlice";
// components
import Loader from "../loader/Loader";
// import logo from "/logo.png";

import "./header.css";

function Header({ setHeaderHeight: setHeaderHeightLocal }) {
  const headerRef = useRef(null);
  const dispatch = useDispatch(null);
  const navigate = useNavigate();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const poemLoading = useSelector((state) => state.poems.isLoading);
  const wordLoading = useSelector((state) => state.words.isLoading);
  const tagsLoading = useSelector((state) => state.tags.isLoading);
  const searchLoading = useSelector((state) => state.search.isLoading);

  useEffect(() => {
    if (poemLoading || wordLoading || tagsLoading || searchLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [poemLoading, wordLoading, tagsLoading, searchLoading]);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);

    // if (windowHeight < 912 && windowHeight > 880) {
    //   dispatch(setMaxLines(35));
    // } else if (windowHeight < 880 && windowHeight > 850) {
    //   dispatch(setMaxLines(34));
    // } else if (windowHeight < 850 && windowHeight > 820) {
    //   dispatch(setMaxLines(33));
    // } else if (windowHeight < 820 && windowHeight > 790) {
    //   dispatch(setMaxLines(32));
    // } else if (windowHeight < 790 && windowHeight > 760) {
    //   dispatch(setMaxLines(31));
    // } else if (windowHeight < 760 && windowHeight > 730) {
    //   dispatch(setMaxLines(30));
    // } else if (windowHeight < 730 && windowHeight > 700) {
    //   dispatch(setMaxLines(29));
    // } else if (windowHeight < 700 && windowHeight > 670) {
    //   dispatch(setMaxLines(28));
    // } else if (windowHeight < 670 && windowHeight > 640) {
    //   dispatch(setMaxLines(27));
    // } else if (windowHeight < 640 && windowHeight > 610) {
    //   dispatch(setMaxLines(26));
    // } else if (windowHeight < 610 && windowHeight > 580) {
    //   dispatch(setMaxLines(25));
    // } else if (windowHeight < 580 && windowHeight > 550) {
    //   dispatch(setMaxLines(24));
    // } else if (windowHeight < 550 && windowHeight > 520) {
    //   dispatch(setMaxLines(23));
    // } else if (windowHeight < 520 && windowHeight > 490) {
    //   dispatch(setMaxLines(22));
    // } else if (windowHeight < 490 && windowHeight > 460) {
    //   dispatch(setMaxLines(21));
    // } else if (windowHeight < 460 && windowHeight > 430) {
    //   dispatch(setMaxLines(20));
    // } else if (windowHeight < 430 && windowHeight > 400) {
    //   dispatch(setMaxLines(19));
    // } else if (windowHeight < 400 && windowHeight > 370) {
    //   dispatch(setMaxLines(18));
    // } else if (windowHeight < 370 && windowHeight > 340) {
    //   dispatch(setMaxLines(17));
    // } else if (windowHeight < 340 && windowHeight > 310) {
    //   dispatch(setMaxLines(16));
    // } else {
    //   dispatch(setMaxLines(34));
    // }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowHeight]);

  useEffect(() => {
    dispatch(getBlockedWords());
    dispatch(getHistory());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setHeaderHeightLocal(headerRef.current.offsetHeight);
      dispatch(setHeaderHeightRedux(headerRef.current.offsetHeight));
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
    }, 1000);
  }, [setHeaderHeightLocal]);

  function handleSubmit(e) {
    e.preventDefault();
    navigate("/");
    dispatch(clearPoems());
    dispatch(getPoemsByTag(search.toLowerCase()));
    dispatch(setCurrentWord(search.toLowerCase()));
  }

  function handleClick() {
    navigate("/");
    dispatch(clearPoems());
    dispatch(resetCurrentWord());
    dispatch(resetCurrentYear());
  }

  return (
    <header ref={headerRef}>
      <li>
        <a to="/" onClick={() => handleClick()}>
          <img
            className="logo"
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="logo"
          />
        </a>
      </li>
      <li>
        <Link to="about">о проекте</Link>
      </li>
      <li>
        <Link to="book">книги</Link>
      </li>
      <li>
        <Link to="voice">вслух</Link>
      </li>
      <li>
        <Link to="press">пресса</Link>
      </li>
      <li>
        <Link to="cartoon">мультик</Link>
      </li>
      <li>
        <Link to="author">об авторе</Link>
      </li>
      <li>
        <form className="search" onSubmit={(e) => handleSubmit(e)}>
          <button type="submit">
            <img src="/search.svg" alt="Search" />
          </button>
          <input
            type="text"
            // placeholder="Слово"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </form>
      </li>
      <Loader isLoading={loading} />
    </header>
  );
}

export default Header;
