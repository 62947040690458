import React, { useState, useEffect } from "react";
// copy to clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";

const link = window.location.hostname;

export default function MeatDataItem({ item, index }) {
  const isTag = item.year == null;
  const [copied, setCopied] = useState(false);

  function getFullDate(date) {
    const formatedDate = new Date(date).toLocaleDateString("uz-UZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formatedDate;
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <>
      <div key={index} className="meta-inspector-item">
        <div>
          {" "}
          {getFullDate(item.createdAt)} вы собрали книгу под названием -
          <span> {isTag ? item.tag : item.year}</span> количество стихотворений
          - {item.poemsCount}, которой вы можете поделиться
        </div>
        <CopyToClipboard
          text={
            link +
            "/?" +
            (isTag ? "word=" : "year=") +
            (isTag ? item.tag : item.year)
          }
        >
          <button className="share-button" onClick={() => setCopied(true)}>
            <img
              src={!copied ? "/assets/shareit.svg" : "/assets/copied.svg"}
              alt="Share"
            />
          </button>
        </CopyToClipboard>
      </div>
    </>
  );
}
