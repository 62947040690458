import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Cloud, renderText } from "react-icon-cloud";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentWord,
  setCurrentYear,
  resetCurrentWord,
  resetCurrentYear,
  getTags,
} from "../../redux/slices/wordSlice";

import {
  getPoemsByTag,
  clearPoems,
  getPoemsByYear,
  handleSendHistory,
} from "../../redux/slices/poemSlice";
// components
import Book from "../book/Book";
// css
import "./sphere.css";

export default function Sphere() {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch(null);
  const allTags = useSelector((state) => state.words.all);
  const currentTag = useSelector((state) => state.words.current);
  const currentYear = useSelector((state) => state.words.year);
  const { poems, isLoading, isSuccess } = useSelector((state) => state.poems);
  const wordLoading = useSelector((state) => state.words.isLoading);
  const tagsLoading = useSelector((state) => state.tags.isLoading);
  const blockedWords = useSelector((state) => state.words.blockedWords);
  const searchLoading = useSelector((state) => state.search.isLoading);

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const [queryParameters] = useSearchParams();

  const targetWord = queryParameters.get("word");
  const targetYear = queryParameters.get("year");

  useEffect(() => {
    if (targetWord) {
      dispatch(clearPoems());
      dispatch(setCurrentWord(targetWord));
      dispatch(getPoemsByTag(targetWord));
    } else if (targetYear) {
      dispatch(clearPoems());
      dispatch(setCurrentYear(targetYear));
      dispatch(getPoemsByYear(targetYear));
    }
  }, []);

  useEffect(() => {
    dispatch(getTags());
  }, []);

  useEffect(() => {
    // delete blocked words from all tags

    let filteredTags = allTags.filter((word) => {
      // Use the find() method to check if the word is in the blockedWords array
      let blockedWord = blockedWords.find((bword) => bword.bword === word);
      return !blockedWord;
    });
    setTags(filteredTags);
  }, [allTags, blockedWords]);

  useEffect(() => {
    if (isLoading || wordLoading || tagsLoading || searchLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading, wordLoading, tagsLoading, searchLoading]);

  // reset current word and year
  function reset() {
    dispatch(resetCurrentWord());
    dispatch(resetCurrentYear());
  }

  // click on tag
  function tagClick(e, tag) {
    e.preventDefault();
    dispatch(clearPoems());
    dispatch(setCurrentWord(tag));
    dispatch(getPoemsByTag(tag)).then(() => {
      dispatch(
        handleSendHistory({
          tag: tag,
          year: null,
        })
      );
    });
  }
  // render for tag cloud
  useEffect(() => {
    let options = {
      textColour: "#0048ff",
      reverse: true,
      fontFamily: "Trebuchet MS, Helvetica, sans-serif",
      textHeight: 1000,
      depth: 0.8,
      zoom: 1,
      maxSpeed: 0.024,
      activeCursor: "pointer",
      textHeight: 15,
      outlineMethod: "block",
      outlineOffset: 5,
      outlineColour: "pink",
      wheelZoom: false,
      fadeIn: 1000,
      initial: [0.1, -0.1],
      decel: 0.98,
      // increse width of sphere
    };
    if (currentTag === "" && currentYear === "") {
      eval(
        `try {
         TagCanvas.Start(
           'myCanvas',
           '',
           {
            textColour: "#376fbf",
           reverse: true,
           fontFamily: 'Trebuchet MS, Helvetica, sans-serif',
           textFont: 'TrixieCyr-Plain',
           depth: 0.8,
            zoom: 1,
            minBrightness: 0.6,
            maxBrightness: 1,
           maxSpeed: 0.024, 
          activeCursor: 'pointer',
          textHeight: 28,
          outlineMethod: 'block',
          outlineOffset: 5,
          outlineColour: '#fae4de',
          wheelZoom: true,
          fadeIn: 1000,
          initial: [0.1,-0.1],
          decel: 0.98,
            // increse width of sphere
          
          }
         );
       }
       catch(e) {
         document.getElementById('myCanvasContainer').style.display = 'none';
       }`
      );
    } else {
    }
  }, [tags, currentTag, currentYear]);

  return (
    <>
      {currentTag !== "" || currentYear !== "" ? (
        <>
          {poems.length > 0 ? (
            <Book />
          ) : (
            <>
              {loading ? (
                <></>
              ) : (
                <h2 className="not-found">
                  По запросу: '{currentTag}'<br /> ничего не найдено
                </h2>
              )}
            </>
          )}
        </>
      ) : (
        <div className="content">
          <canvas width={900} height={900} id="myCanvas">
            <p>
              Anything in here will be replaced on browsers that support the
              canvas element
            </p>
            <ul>
              {tags.map((tag, i) => (
                <li key={tag + i}>
                  <a href="#" onClick={(e) => tagClick(e, tag)}>
                    {tag}
                  </a>
                </li>
              ))}
            </ul>
          </canvas>
        </div>
      )}
    </>
  );
}

// Изменить расположнгие карточек во вкладке "Книги"
// (not done)

// оставить карточки в ряду на одинаковом уровнем с годами
// (done)

// Мультик также на одинаковом растоянии с годами
// (done)

// добавить тонкую линию для года или слова в книге
// (done)

// добавить расстояние от номера страницы до текста

// сместить текст поэмы левее

// 03.02.2023

// Страницы к 0 при созданиие новой книги
// Удалить скролл из админки

// 22.02

// Исправить удаление стихотворения
// Показывать количество стихотворений в книге а не страниц
// Добавить валидацию для нового стизотворения
// Переработать админку
// Увеличить шрифт на BookSale
// Изменить поиск по словам