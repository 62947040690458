import React from "react";
import "../admin.css";
import { TrashIcon } from "../../Icons";
import {
  deleteBlockedWords,
  deleteBlockWord,
  addBlockedWord,
} from "../../../redux/slices/wordSlice";
import { useSelector, useDispatch } from "react-redux";

const BlockedWords = () => {

  const dispatch = useDispatch();

  const [word, setWord] = React.useState("");

  const state = useSelector((state) => state.words);

  const { isSuccess, blockedWords } = state;

  function deleteWord(id) {
    dispatch(deleteBlockedWords(id));
    dispatch(deleteBlockWord(id));
  }

  function handleAddBlockedWord() {
    dispatch(addBlockedWord({ bword: word }));
  }

  return (
    <>
      <p>Найдено {blockedWords.length} слова</p>
      <div className="usedList">
        <div>
          <input type="type" onChange={(e) => setWord(e.target.value)} />
          <button onClick={() => handleAddBlockedWord()}>Добавить слово</button>
        </div>
        <div className="tags-box">
          {isSuccess &&
            blockedWords.map((item, i) => {
              return (
                <div className="tag-item" key={i}>
                  <p>{item.bword}</p>
                  <span onClick={() => deleteWord(item._id)}>
                    <TrashIcon />
                  </span>
                </div>
              );
            })}
        </div>
        {/* {blockedWords.map((word, i) => (
          <div key={i}>
            <p className="word">{word}</p>
          </div>
        ))} */}
      </div>
    </>
  );
};

export default BlockedWords;
