import React, { useState, useEffect, useRef } from "react";
// react-redux
import { useSelector, useDispatch } from "react-redux";
// react-swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
// css
import "./voice.css";
// import required modules
import { EffectCreative } from "swiper";
// data
import { links } from "./link";

const Page = (props) => {
  const { setAudio, audio } = props;

  function playAudio(link) {
    if (audio === link) {
      setAudio(null);
    } else {
      setAudio(link);
    }
  }
  const [maxLines, setMaxLines] = useState(27)
 

  return (
    <div className={props.class === "left" ? "page left" : "page right"}>
      <p className={props.class === "left" ? "leftTitle" : "rightTitle"}>
        вслух
      </p>
      <div className="pageBody">
        {props.voice.map((item, i) => {
          return (
            <div className="voice-item" key={i}>
              <a href="#" onClick={() => playAudio(item.file)}>
                {i + (props.number - 1) * maxLines + 1}. {item.title}
              </a>
            </div>
          );
        })}
      </div>
      <div className="page-footer">{props.number}</div>
    </div>
  );
};

export default function Voice() {
  const [voiceLinks, setVoiceLinks] = useState([]);
  const [audio, setAudio] = useState(null);

  const audioRef = useRef(null);

  const maxLines = 27;

  function convertArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }
  // reverse array
  function reverseArray(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
  }

  useEffect(() => {
    const linksArray = reverseArray(links, true);

    // converting array to 2d array
    setVoiceLinks(convertArray(linksArray, maxLines));
  }, [maxLines]);
  useEffect(() => {
    if (audio !== null) {
      playAudio();
    } else {
      pauseAudio();
    }
  }, [audio]);

  async function playAudio() {
    audioRef.current.pause();
    audioRef.current.load();
    audioRef.current.play();
  }
  function pauseAudio() {
    audioRef.current.pause();
  }

  return (
    <div className="flip-book">
      <audio controls={false} ref={audioRef}>
        <source
          src={"https://archive.org/download/inikfinik/" + audio}
          type="audio/mpeg"
        />
      </audio>
      <Swiper
        grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
      >
        {voiceLinks.map((voice, index) => {
          if (index % 2 === 0) {
            return (
              <SwiperSlide key={index}>
                <Page
                  number={index + 1}
                  class={"left"}
                  voice={voice}
                  audio={audio}
                  setAudio={setAudio}
                />
                {voiceLinks[index + 1] !== undefined ? (
                  <Page
                    number={index + 2}
                    class={"right"}
                    voice={voiceLinks[index + 1]}
                    audio={audio}
                    setAudio={setAudio}
                  />
                ) : (
                  <Page number={index + 2} class={"right"} voice={[]} />
                )}
              </SwiperSlide>
            );
          }
        })}
      </Swiper>
    </div>
  );
}
