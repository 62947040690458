import React from "react";
// react-redux
import { useDispatch, useSelector } from "react-redux";
// components
import Admin from "../components/admin/Admin";
import Login from "../components/admin/Login";

const AdminPage = () => {
  const dispatch = useDispatch(null);

  const userData = useSelector((state) => state.user.data);

  return <>{Object.keys(userData).length !== 0 ? <Admin /> : <Login />}</>;
};

export default AdminPage;
