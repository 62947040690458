import { useState, useEffect } from "react";
// react-redux
import { useDispatch, useSelector } from "react-redux";
// components
import MeatDataItem from "../metadata/MeatDataItem";
// css
import "./metaInspector.css";
export default function MetaInspector() {
  const historyApi = useSelector((state) => state.poems.history);
  const [history, sethistory] = useState(null);

  useEffect(() => {
    if (historyApi && historyApi.length > 0) {
      // reverse array
      sethistory([...historyApi].reverse());
      let arr = [1, 2, 3];
    }
  }, [historyApi]);

  return (
    <div className="meta-inspector">
      {history &&
        history.map((item, i) => (
          <MeatDataItem item={item} index={i} key={i} />
        ))}
    </div>
  );
}
