import { useEffect, useState, useRef } from "react";
// components
import BookSaleItem from "./BookSaleItem";

export default function BookSale() {
  const saleContainerRef = useRef(null);

  // get header height

  // useEffect(() => {
  //   const header = document.querySelector("header");
  //   const headerHeight = header.offsetHeight;
  //   // set height of sale container winfow height - header height
  //   saleContainerRef.current.style.height = `${
  //     window.innerHeight - headerHeight - 25
  //   }px`;
  // }, []);

  return (
    <div>
      <div className="sale-container" ref={saleContainerRef}>
        <BookSaleItem />
      </div>
    </div>
  );
}
