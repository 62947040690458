import { useState, useEffect } from "react";
// hooks
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function BorderBottom({ yearsWidth, headerHeight }) {
  const { height, width } = useWindowDimensions();
  const [bottomBorderHeight, setBottomBorderHeight] = useState("0");
  const [bottomBorderWidth, setBottomBorderWidth] = useState("0");

  useEffect(() => {
    setBottomBorderHeight(headerHeight);
  }, [headerHeight, height]);

  useEffect(() => {
    setBottomBorderWidth(width - yearsWidth * 2 + 2);
  }, [yearsWidth, width]);

  return (
    <div
      className="bottomBorder"
      style={{
        height: bottomBorderHeight / 3 + bottomBorderHeight / 3,
        width: bottomBorderWidth,
        left: yearsWidth - 1,
      }}
    ></div>
  );
}
