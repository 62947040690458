import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  searchPoemQuery,
  removePoem,
  getAllPoems,
} from "../../../redux/slices/searchSlice";
import {
  setCurrentPoem,
  openModal,
  setDeleteDialog,
  deletePoem,
} from "../../../redux/slices/poemSlice";
import { setCurrentTab } from "../../../redux/slices/tabsSlice";
import PoemsTable from "./PoemsTable";

export default function Names() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.search);

  const poemsState = useSelector((state) => state.poems);

  const { textModal, selectedPoem, deletingPoem, deleteDialog } = poemsState;

  const { isSuccess, data, allWordsCount } = state;

  const [title, setTitle] = useState("");
  const [word, setWord] = useState("");
  const [year, setYear] = useState("");

  function handleSetPoem(poem) {
    dispatch(setCurrentPoem(poem));
    dispatch(setCurrentTab("2"));
  }

  function handleSearch() {
    dispatch(searchPoemQuery({ title, word, year }));
  }

  function searchAll() {
    dispatch(getAllPoems());
  }

  function handleReset() {
    setTitle("");
    setWord("");
    setYear("");
  }

  function handleRemovePoem() {
    dispatch(deletePoem(deletingPoem._id));
    dispatch(removePoem(deletingPoem._id));
    dispatch(setDeleteDialog());
  }

  function handleValidate() {
    if (!title && !word && !year) {
      alert("Пожалуйиста введите данные для поиска");
    } else {
      handleSearch();
    }
  }
  useEffect(() => {
    if (data.length === 0) {
      searchAll();
    }
  }, []);

  return (
    <>
      {deleteDialog && (
        <>
          <div className="darkBG" />
          <div className="centered">
            <div className="modalDialog">
              <div className="modalHeader">
                <h5 className="heading">Подтвердите действие</h5>
              </div>
              <button
                className="closeBtn"
                onClick={() => dispatch(setDeleteDialog())}
              >
                X
              </button>
              <div className="modalContent">
                <p>Удалить стих: {deletingPoem.title}</p>
                <p>Количество слов: {deletingPoem.tags.length}</p>
              </div>
              <div className="modalActions">
                <div className="actionsContainer">
                  <button
                    className="deleteBtn"
                    onClick={() => handleRemovePoem()}
                  >
                    Удалить
                  </button>
                  <button
                    className="cancelBtn"
                    onClick={() => dispatch(setDeleteDialog())}
                  >
                    Отменить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {textModal && (
        <>
          <div className="darkBG" />
          <div className="centered">
            <div className="modal">
              <div className="modalHeader">
                <h5 className="heading">{selectedPoem.title}</h5>
              </div>
              <button
                className="closeBtn"
                onClick={() => dispatch(openModal())}
              >
                X
              </button>
              <div className="modalContent">{selectedPoem.body}</div>
            </div>
          </div>
        </>
      )}
      <div style={{ margin: 5 }}>
        <p className="heading">Поиск по...</p>
        <p className="word">
          слову в названии{" "}
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </p>
        <p className="word">
          слову в тексте{" "}
          <input
            type="text"
            value={word}
            onChange={(e) => setWord(e.target.value)}
          />
        </p>
        <p className="word">
          году{" "}
          <input
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </p>
        <div style={{}}>
          <button className="btn-danger" onClick={() => handleReset()}>
            Очистить
          </button>
          <button className="btn-primary" onClick={() => handleValidate()}>
            Поиск
          </button>
          <button className="btn-primary" onClick={() => searchAll()}>
            Загрузить все
          </button>
        </div>
        <div className="statistics">
        <p className="word">Найдено: {data.length} </p>
        <p className="word">Всего слов: {allWordsCount}</p>
        </div>
        

        <div className="table-box">
          <table id="table">
            <thead>
              <tr>
                <th>Номер</th>
                <th>Название</th>
                <th>Количество слов</th>
                <th>Год</th>
                <th>Действие</th>
              </tr>
            </thead>
            <tbody>
              {isSuccess &&
                data.map((item, i) => (
                  <PoemsTable
                    poems={item}
                    number={i}
                    handleSetPoem={handleSetPoem}
                    key={item._id}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

{
}
