import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const BASE_URL = "https://node.inesse.uz";

export const getallTags = createAsyncThunk("poem/getAllTags", async () => {
  const response = await axios.get(`${BASE_URL}/api/poem/all`);
  return response.data;
});

const tagSlice = createSlice({
  name: "tagSlice",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    tags: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getallTags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getallTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tags = action.payload;
      })
      .addCase(getallTags.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { setPoems } = tagSlice.actions;

export default tagSlice.reducer;
