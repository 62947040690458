import React, { useEffect, useState } from "react";

export default function BookSaleItem() {
  const [currentBook, setCurrentBook] = useState(null);

  function changeBook(book) {
    setCurrentBook(book);
  }

  const booksOne = [
    {
      id: 1,
      title: "Мой хадж",
      description:
        "Издательство литературы и искусства им. Г.Гуляма, Ташкент 1993 год",
      image: "/assets/book-sale/5.png",
    },
    {
      id: 2,
      title: "Dao к себе",
      description:
        "Издательство Фан Академии наук Республики Узбекистан, Ташкент 1996 год",
      image: "/assets/book-sale/1.png",
    },
    {
      id: 3,
      title: "Истории Бездорожья",
      description:
        "Издательство литературы и искусства им .Г.Гуляма, Ташкент 1999 год",
      image: "/assets/book-sale/2.png",
    },
  ];

  const booksTwo = [
    {
      id: 4,
      title: "Мозаика",
      description: "Издательство Полеспечать Гомель, 2000 год",
      image: "/assets/book-sale/35.png",
    },
    {
      id: 5,
      title: "Злато-Солнце-Швея",
      description: "Издательство Русь Москва, 2000 год",
      image: "/assets/book-sale/6.png",
    },
    {
      id: 6,
      title: "Сундук",
      description: "Книга не вышла в печати, 2008 год",
      image: "/assets/book-sale/4.png",
    },
  ];

  return (
    <>
      <div className="center list flex-row">
        {booksOne.map((book, i) => (
          <BookItem
            key={i}
            book={book}
            currentBook={currentBook}
            changeBook={changeBook}
          />
        ))}
      </div>
      <div className="center list flex-row">
        {booksTwo.map((book, i) => (
          <BookItem
            key={i}
            book={book}
            currentBook={currentBook}
            changeBook={changeBook}
          />
        ))}
      </div>
    </>
  );
}

function BookItem({ book, currentBook, changeBook }) {
  const { title, description, image } = book;

  return (
    <>
      {/* <div className="cardWradpper"> */}
      <div className={`card`}>
        <img src={image} className="book" alt={title} />
        <div className="info">
          <div className="book-title">{title}</div>
          <div className="book-decription">{description}</div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
