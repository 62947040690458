import React from "react";
import "../admin.css";
import { getallTags } from "../../../redux/slices/tagsSlice";
import { useDispatch, useSelector } from "react-redux";

import "../admin.css";

const UsedList = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.tags);
  const blockedWords = useSelector((state) => state.words.blockedWords);
  const { tags, isLoading, isError, isSuccess } = state;
  const [filteredTags, setFilteredTags] = React.useState([]);

  const [search, setSearch] = React.useState("");
  const [searched, setSearched] = React.useState([]);

  React.useEffect(() => {
    let searched = [];
    searched = tags.find((element) => element.tag === search);
    setSearched(searched);
  }, [search]);

  // const [localTags, setlocalTags] = React.useState([]);

  // React.useEffect(() => {
  //   setlocalTags(tags);
  // }, [tags]);

  function handleGetTags() {
    dispatch(getallTags());
  }
  React.useEffect(() => {
    let filteredTags = tags.filter((word) => {
      // Use the find() method to check if the word is in the blockedWords array
      let blockedWord = blockedWords.find((bword) => bword.bword === word.tag);
      return !blockedWord;
    });

    setFilteredTags(filteredTags);
  }, [tags, search, blockedWords]);

  return (
    <div className="usedList">
      {/* loading button */}
      <button className="btn-success" onClick={() => handleGetTags()}>
        Загрузить
      </button>
      <input
        type="search"
        placeholder="Поиск"
        onBlur={(e) => setSearch(e.target.value)}
      />
      <button className="btn-primary">Поиск</button>
      <h1>
        Всего в стихотворениях использовано {tags ? tags.length : 0} слов не
        считая {blockedWords.length} блокированных слов
      </h1>
      <div>{isError && <p>Ошибка загрузки</p>}</div>
      {isLoading && <p>Загрузка...</p>}
      {isSuccess && searched && (
        <div className="tag-item">
          <p>{searched.tag}</p>
          <span>{searched.count}</span>
        </div>
      )}

      {isSuccess && !searched && (
        <div className="tags-box">
          {filteredTags.map((item, i) => {
            return (
              <div className="tag-item" key={i}>
                <p>{item.tag}</p>
                <span>{item.count}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UsedList;

// tags.map((item, i) => {
//   return (
//     <div className="tag-item" key={i}>
//       <p>{item.tag}</p>
//       <span>{item.count}</span>
//     </div>
//   );
// })
